/* eslint-disable no-undef */
import React from 'react';
import Banner from './Banner';
import { Box, Container, Typography, List, ListItem, ListItemText, Link, Grid } from '@mui/material';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Paper
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NidhiSspCard from './nidhiSspCard';
import SspImage from '../../../Media/Nidhi_SSS.jpg'

const sectionStyle = {
    py: 4,
    backgroundColor: "#ffffff",
    color: "#1C1F35",
};

const sectionStyle2 = {
    py: 4,
    backgroundColor: "#d7e3fc",
    color: "#1C1F35",
};

const startups = [
    {
        Name: 'Wolt Powertronics Pvt. Ltd.',
        Website: 'https://wolt.co.in/',
        Domain: 'Air Purification, Healthcare, Renewable Energy',
        Description: `Wolt Powertronics Pvt. Ltd. is a pioneering company dedicated to delivering advanced solutions in air purification, sanitization, healthcare, and renewable energy. Their innovative products address pressing global challenges such as air pollution, pathogen spread, personal health monitoring, and sustainable energy.`,
        // logo: `${process.env.REACT_APP_STP_URL}/`    
    },
    {
        Name: 'Elementik Technologies Pvt. Ltd.',
        Website: 'https://www.byteseal.in/',
        Domain: 'Cyber Security',
        Description: `Elementik Technologies' Byteseal Biometric ID addresses the global surge in cybercrime, which is predominantly caused by human errors such as weak passwords and phishing attacks. Byteseal combines advanced biometric authentication with Bluetooth and NFC, allowing users to log into online accounts with a fingerprint tap, eliminating these vulnerabilities.`,
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/SSS/byteseal_logo-+elementik+technologies.jpg`
    },
    {
        Name: 'Electromotion E-Vidyut Vehicles Pvt. Ltd.',
        Website: 'https://evidyut.in/',
        Domain: 'Electric Vehicles',
        SupportedInFY: 'N/A',
        Description: `Electromotion focuses on developing electric retrofitment solutions for existing vehicles, targeting India's autorickshaw fleet with their innovative RetroKit™. This modular gearbox assembly allows for the conversion of traditional vehicles into electric-powered ones, making transportation more eco-friendly and cost-effective.`,
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/SSS/Electromotion+Logo.png`
    },
    {
        Name: 'Pixaflip Technologies Pvt. Ltd.',
        Website: 'https://pixaflip.com/',
        Domain: 'EdTech, SaaS',
        Description: `Pixaflip Technologies offers cutting-edge platforms like Acadflip and Flipgrad, tailored for personalized learning. Their Chamber SaaS platform helps institutions manage operations efficiently, alongside expertise in cross-platform development and cloud deployment.`,
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/SSS/pixaflip_logo.jpg`
    },
    {
        Name: 'Natural Solutions and Nutraceuticals Pvt. Ltd.',
        Website: 'https://www.nsnpl2health.com/',
        Domain: 'Healthcare, Nutraceuticals',
        Description: `Natural Solutions and Nutraceuticals is known for its polyherbal formulations targeting critical health issues. Their products like Virulina, Hypercum, and Hepadetox offer evidence-based, clinically evaluated natural alternatives to modern medical treatments.`,
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/SSS/Natural%2BSolutions%2Band%2BNeutraceuticals%2BLogo-Photoroom.png`
    },
    {
        Name: 'Vemsa Biotech Pvt. Ltd.',
        Website: 'https://vemsabiotech.com/',
        Domain: 'Biotechnology, Healthcare',
        Description: `Vemsa Biotech introduces a groundbreaking Ayurvedic solution for Parkinson’s Disease through nasal drops. These drops deliver 6% L-Dopa directly via the nasal passage, enhancing efficacy and reducing side effects.`,
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/SSS/Vemsa+logo.png`
    },
    {
        Name: 'Hasber Machine Pvt. Ltd.',
        Website: 'https://hasber.in/',
        Domain: 'Construction Technology',
        Description: `Hasber Machine Pvt. Ltd. developed India’s first patented mobile concrete plant, the HASBER C-30. This portable Ready-Mix Concrete (RMC) unit integrates a twin-shaft mixer and fully automated batching system for efficient concrete production.`,
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/SSS/Hasber+Logo.jpg`
    },
];

const NidhiSsp = () => {

    return (
        <>
            <Banner title="NIDHI SSS" letterSpacing='5px' />
            <Box sx={{
                ...sectionStyle,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                py: { xs: 3, sm: 4 },
            }}>
                <Container sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: '1200px',
                    padding: 0,
                    px: { xs: 2, sm: 5 },
                }}>
                    <Box sx={{
                        width: { xs: '100%', sm: '50%' },
                        paddingRight: { xs: 0, sm: 5 },
                        textAlign: { xs: 'center', sm: 'left' },
                    }}>
                        <Typography
                            variant="body1"
                            paragraph sx={{
                                color: '#5A7184',
                                lineHeight: 1.6,
                                textAlign: 'justify',
                                marginBottom: 2,
                            }}>
                            The NIDHI Seed Support Programme (NIDHI-SSP) is an initiative by the National Science & Technology Entrepreneurship Development Board (NSTEDB) under the Department of Science & Technology, Government of India. The primary goal of the NIDHI Seed Support is to offer financial aid to promising startups with innovative ideas, technologies, or solutions. <br /> <br />
                            This program provides essential support to young companies, helping them navigate the crucial early stages of their business journey. By offering specialized services during this critical phase, NIDHI Seed Support enables startups to develop their innovations to a point where they can pursue traditional funding sources, such as commercial banks or financial institutions, ultimately leading to successful commercialization. In essence, the program acts as a bridge between the development and commercialization stages of technology.                        </Typography>
                    </Box>
                    <Box sx={{
                        width: { xs: '80%', sm: '50%' },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: { xs: 3, sm: 0 },
                    }}>
                        <img src={SspImage} alt='startup' style={{
                            width: '100%',
                            maxWidth: '400px',
                            borderRadius: '5%',
                        }} />
                    </Box>
                </Container>
            </Box>

            <Box sx={sectionStyle2}>
                <Grid container spacing={12} alignItems="center" justifyContent="center">
                    <Grid item xs={12} md={12}>
                        <Typography
                            variant="h3"
                            sx={{ mt: 1, color: "#0099DE", fontWeight: 'bold', textAlign: 'center' }}
                        >
                            Financial Support
                        </Typography>

                        <Typography
                            variant="h6"
                            sx={{ color: "#364356", fontWeight: 'normal', mt: 0.5, textAlign: 'center' }}
                        >
                            up to
                        </Typography>

                        <Typography
                            variant="h2"
                            sx={{ color: "black", fontWeight: 'bold', mt: 0.5, textAlign: 'center' }}
                        >
                            &#8377; 1 Cr.
                        </Typography>

                        <List sx={{ mt: 0.5, textAlign: 'center', color: "#364356" }}>
                            <ListItem>
                                <ListItemText
                                    primary="Equity-linked instruments as the mode of financing/investing into start-ups"
                                    sx={{ textAlign: 'center' }}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: '#ffffff',
                    py: 4,
                    px: { xs: 2, md: 12 },
                    width: '100%',
                    // maxWidth: '900px',
                    margin: 'auto',
                }}
            >
                <Container sx={{ py: { md: 1 } }}>
                    <Typography color="#152265" variant="h4" fontWeight="600" align="center" gutterBottom>
                        Objectives
                    </Typography>
                    <List sx={{ color: "#364356", display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 0 }}>
                        <ListItem sx={{ justifyContent: 'center', }}>
                            <ListItemText
                                primaryTypographyProps={{ fontSize: "1.1rem",textAlign: 'justify' }}
                                primary="1. Ensure the timely availability of seed funding to deserving startups within incubators, enabling the growth of promising innovations and technologies." />
                        </ListItem>
                        <ListItem sx={{ justifyContent: 'center' }}>
                            <ListItemText
                                primaryTypographyProps={{ fontSize: "1.1rem",textAlign: 'justify' }}
                                primary="2. Facilitate the creation of techno-entrepreneurs, while acting as a vital link between technology development and successful commercialization." />
                        </ListItem>
                    </List>
                </Container>
                <Paper
                    sx={{
                        backgroundColor: '#d7e3fc',
                        padding: '16px',
                        marginBottom: '16px',
                        borderRadius: '8px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        width: '80%',
                        // maxWidth: '600px',
                    }}
                >
                    <Accordion sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 0 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 600, color: "#152265" }}>
                                Eligibility Criteria
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: "#5A7184",textAlign: 'justify' }}>
                                <ol>
                                    <li>The applicant start-up should be a registered company in India, preferably a DPIIT-registered/applied start-up, with a minimum of three months of residency at the STEP/TBIS.</li>
                                    <li>The applicant start-up must be an Indian start-up. This support is not meant for Indian subsidiaries of MNCs/foreign companies. Persons holding Overseas Citizenship of India (OCI) or Persons of Indian Origin (PIO) will be considered Indian citizens for this scheme.</li>
                                    <li>The shareholding by Indian promoters in the incubatee start-up should be at least 51%.</li>
                                    <li>Preference will be given to start-ups that have not availed of any funding from any Government of India or State Government body.</li>
                                    <li>The applicant start-up should have developed clarity on its Unique Selling Proposition (USP) through customer validation and value proposition for its targeted customers.</li>
                                    <li>Pan India program support considers "Virtual" incubation.</li>
                                </ol>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>

                <Paper sx={{
                    backgroundColor: '#d7e3fc',
                    padding: '16px',
                    marginBottom: '16px',
                    borderRadius: '8px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    width: '80%',
                    // maxWidth: '600px',
                }}>
                    <Accordion sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 0 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 600, color: "#152265" }}>
                                Time Period
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: "#5A7184" }}>
                                <strong>2 - 3 years</strong>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
                <Paper
                    sx={{
                        backgroundColor: '#d7e3fc',
                        padding: '16px',
                        marginBottom: '16px',
                        borderRadius: '8px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        width: '80%',
                        // maxWidth: '600px',
                    }}
                >
                    <Accordion sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 0 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 600, color: "#152265" }}>
                                Broad Area Supported
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: "#5A7184",textAlign: 'justify' }}>
                                <ul>
                                    <li>Product development</li>
                                    <li>Testing and trials</li>
                                    <li>Test marketing</li>
                                    <li>Mentoring</li>
                                    <li>
                                        Professional consultancy (To attract professors/experts from institutions to work with start-ups.)
                                    </li>
                                    <li>IPR issues</li>
                                    <li>Manpower for day-to-day operations</li>
                                    <li>
                                        Any other area as deemed necessary and recommended by the Seed Support Management Committee of
                                        STEP/TBIS.
                                    </li>
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            </Box>

            <Box sx={sectionStyle2}>
                <Container>
                    <Typography color="#152265" variant="h4" fontWeight="600" align="center" py={2} mb={3}>
                        Supported Startups
                    </Typography>
                    <Grid container spacing={3} justifyContent="center">
                        {startups.map((startup, index) => (
                            <Grid item xs={12} sm={6} md={5} key={index}>
                                <NidhiSspCard item={startup} />
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default NidhiSsp;
