import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Grid,
    Card,
    CardMedia,
    CardContent,
    Button,
} from "@mui/material";
import axios from "axios";

export default function SpecialProjects() {
    const [specialProjects, setSpecialProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";

    useEffect(() => {
        const fetchSpecialProjects = async () => {
            try {
                const response = await axios.get("https://api.scitechpark.org.in/specialproject/getall");
                console.log(response?.data);
                const allData = response?.data?.result;
                if (Array.isArray(allData)) {
                    const latestData = allData.slice(-4).reverse();
                    setSpecialProjects(latestData);
                } else {
                    throw new Error("Invalid response data");
                }
            } catch (error) {
                console.error(error);
                setError("Failed to fetch special projects. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        fetchSpecialProjects();
    }, []);

    return (
        <Box
            sx={{
                backgroundColor: "#d7e3fc",
                py: 6,
                px: 2,
                color: "#1c2b78",
            }}
        >
            <Typography
                variant="h4"
                textAlign="center"
                mb={4}
                sx={{
                    fontWeight: "bold",
                    letterSpacing: "0.05em",
                }}
            >
                Special Projects
            </Typography>
            {loading ? (
                <Typography textAlign="center" mt={2}>
                    Loading...
                </Typography>
            ) : error ? (
                <Typography textAlign="center" mt={2} color="error">
                    {error}
                </Typography>
            ) : specialProjects.length === 0 ? (
                <Typography textAlign="center" mt={2}>
                    No special projects available at the moment.
                </Typography>
            ) : (
                <Grid
                    container
                    spacing={3}
                    justifyContent="center"
                    p={{
                        xs: 1,
                        sm: "2rem 4rem",
                        md: "0rem 12rem"
                    }}
                >
                    {specialProjects.map((project, index) => (
                        <Grid item xs={12} sm={6} md={3} key={project.Project_id}>
                            <Card
                                sx={{
                                    maxWidth: "100%",
                                    backgroundColor: "#fff",
                                    borderRadius: '10px',
                                    color: "#000",
                                    height: "100%",
                                    boxShadow: 3,
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    image={`${docUrl}${project.Pro_image}`}
                                    alt={project.Title || "Special Project Image"}
                                    sx={{
                                        height: 114,
                                        width: "95%",
                                        objectFit: "contain",
                                        margin: '1rem auto 0 auto',
                                        border: "1px solid #1c2b78",
                                    }}
                                />

                                <CardContent sx={{ textAlign: "left", padding: "0.5rem 1rem" }}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: "bold",
                                            color: "#1c2b78",
                                        }}
                                    >
                                        {project.Title}
                                    </Typography>
                                </CardContent>
                                <CardContent sx={{ flexGrow: 1, padding: "0.5rem 1rem", textAlign: "justify" }}>
                                    <Typography variant="body2" color="textSecondary" sx={{ lineHeight: 1.5 }}>
                                        {project.Description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
            <Box textAlign="center" mt={6}>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#1c2b78",
                        color: "white",
                        padding: "1rem 3rem",
                        fontWeight: 550,
                        fontSize: "14px",
                        textTransform: "none",
                        borderRadius: "10px",
                        ":hover": {
                            backgroundColor: "#1e3192",
                        },
                    }}
                    onClick={() => window.open("/special-projects", "_self")}
                >
                    More Special Projects
                </Button>
            </Box>
        </Box>
    );
}
