import React  from "react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { staticData } from "./staticData";
import GeoinformaticBanner from "./GeoinformaticBanner"; 
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const SingleGeoinformaticContent = () => {
  const { data } = useParams();
  const navigate = useNavigate();

  // Find the news item with the matching slug
  const item = staticData.find((item) => item.slug === data);
  if (!item) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "75vh",
        }}
      >
        <Typography variant="h6">Data not found</Typography>
      </Box>
    );
  }

  return (
    <>
      <GeoinformaticBanner title={item.Title} imgData={item.Images} />

      <IconButton
        onClick={() => navigate("/geoinformatics")}
        sx={{
          color: "black",
          position: "relative",
          ml: { xs: "10px", md: "90px" },
          mb: "-50px",
        }}
      >
        <ArrowBackIcon />
      </IconButton>

      {/* Content Section */}
      <Box sx={{ bgcolor: "#f4f6fc", padding: { xs: "1.5rem", md: "1rem 6rem" }, marginTop: "3rem" }}>
        <Typography variant="body1" fontSize="1.1rem" gutterBottom my="0.1rem" color="#364356" textAlign="justify">
          {item.Description}
        </Typography>
      </Box>

      {/* Clientele Section */}
      <Box sx={{ py: 4, px: { xs: 2, md: 8 }, backgroundColor: "#ccdbfd", color: "#1C1F35" }}>
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ color: "#152266", fontSize: "1.2rem", fontWeight: "bold", textAlign: "center" }}>
              Clientele
            </Typography>
          </Grid>
          {(Array.isArray(item.Clientele) ? item.Clientele : item.Clientele.split(",")).map((client, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={{
                  backgroundColor: "rgb(184 199 245)",
                  borderRadius: "8px",
                  padding: "0.5rem",
                  textAlign: "center",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  height: "80px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px solid rgb(28 43 120)",
                }}
              >
                <Typography variant="body2" sx={{ color: "#152266", fontWeight: "600", fontSize: "0.9rem" }}>
                  {typeof client === "string" ? client.trim() : client}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>

        <IconButton onClick={() => navigate("/geoinformatics")} sx={{ color: "black" }}>
          <ArrowBackIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default SingleGeoinformaticContent;
