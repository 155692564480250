import React from 'react'
import Content from './content';
// import Banner from '../../Components/Banner';
import GeoinformaticBanner from './GeoinformaticBanner';

const imgData = [
    `${process.env.REACT_APP_STP_URL}/geoinformaticsStaticData/MainBanners/1.jpg`,
    `${process.env.REACT_APP_STP_URL}/geoinformaticsStaticData/MainBanners/2.jpg`,
    `${process.env.REACT_APP_STP_URL}/geoinformaticsStaticData/MainBanners/3.jpg`,
    `${process.env.REACT_APP_STP_URL}/geoinformaticsStaticData/MainBanners/4.jpg`,
  ];

const GeoinformaticsPage = () => {
    return (
        <>
            <GeoinformaticBanner title="Geoinformatics" imgData={imgData} />
            <Content />
        </>
    )
}

export default GeoinformaticsPage;